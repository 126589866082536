
  import { Component, Watch } from 'vue-property-decorator'
  import FormTitle from '@/components/forms/FormTitle.vue'
  import Row from '@/components/toolkit/details/row/row.vue'
  import { fixPrice, stringifySafe } from '@/utils/general'
  import GFormSlot from '@/components/forms/GFormSlot.vue'
  import GRadioButton from '@/components/core/input/GRadioButton.vue'
  import GFiles from '@/components/core/files/GFiles.vue'
  import GDatePicker from '@/components/core/input/GDatePicker.vue'
  import { Payment } from '@/entities/finance'
  import { SaleOrderView } from '@/components/forms/view/SaleOrderView'
  import { SaleOrder } from '@/entities/sales'
  import GAlert from '@/components/core/alert/GAlert.vue'
  import { plainToInstance } from 'class-transformer'
  import BaseForm from '@/components/forms/view/BaseForm.vue'
  import { Evaluation } from '@/entities/loans'
  import { Debounce } from '@/utils/decorators'

@Component({
  components: { BaseForm, GAlert, GDatePicker, GFiles, GRadioButton, GFormSlot, Row, FormTitle },
  methods: { fixPrice },
})
  export default class treasurerIncomeForm extends SaleOrderView {
  saleOrder: SaleOrder | null
  evaluation: Evaluation | null

  showDetail = false
  supervisor = false
  disabled = false
  isSale = true
  title = 'ABONO'
  payment: Payment = plainToInstance(Payment, {})

  declare $refs: {
    form: HTMLFormElement
    payment: GFormSlot
  };

  alert = {
    open: false,
    title: '',
  }

  status = {
    toUpdate: null,
    successfull: null,
  }

  formData = {
    purchaseBackup: [],
    exist: null,
    paymentProof: [],
    confirm: [],
    date: null,
    comment: '',
    letterApproval: [],
  }

  metadataCollection = null
  closed = null
  paymentProofMessage = null
  radioButtonMessage = ''
  loading = false
  paymentType = null
  paymentFileProperties = []
  filesPropertiesConfirm = {
    accept: '',
    multiple: false,
    fileTypeId: null,
    name: '',
    label: '',
    required: false,
    icon: '',
  }

  async mounted () {
    await this.setMetadata()

    const { uid } = this

    if (uid) {
      await this.getPayment(uid)
    }

    if (!this.isBreadCrumbPresent(this.title)) {
      this.setFormCrumbs(this.metadataCollection, this.title, Boolean(this.payment?.id))
    }

    await this.setDetails()
  }

  async setDetails () {
    const { metadataCollection, payment } = this

    if (payment?.deal?.stock?.id) {
      payment.deal.stock = await this.fetchData({
        query: { name: 'fetch', model: 'Stock', params: { id: payment.deal.stock.id } },
        force: true,
      })
    }
    this.metadata = {
      data: payment,
      metadata: metadataCollection,
    }

    this.showDetail = Boolean(payment?.id)
  }

  async setMetadata () {
    const { metadata } = this.getForm('Payment', 'treasurer_income')
    this.metadataCollection = metadata
    this.closed = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'payment' } } }, { status: { name: { _eq: 'closed' } } }] },
    })

    this.status.toUpdate = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'payment' } } }, { status: { name: { _eq: 'to_update' } } }] },
    })

    this.status.successfull = await this.fetchData({
      query: { name: 'find', model: 'ClosingReason' },
      filter: {
        _and: [
          { type: { name: { _eq: 'successful' } } },
          { status: { process: { table_name: { _eq: 'payment' } } } },
        ],
      },
    })

    this.paymentFileProperties = await this.fetchData({
      query: { name: 'find', model: 'FileParameter' },
      filter: { _and: [{ process: { table_name: { _eq: 'payment' } } }] },
    })

    const file = this.paymentFileProperties?.find(file => file.name === 'payment_confirmation')

    if (file) {
      this.filesPropertiesConfirm = {
        accept: file.fileType.mimes,
        multiple: file.multiple,
        fileTypeId: file.fileType.id,
        name: file.name,
        label: file.description,
        required: file.required,
        icon: file.fileType.icon,
      }
    }
  }

  async getPayment (id) {
    this.payment = await this.fetchData({
      query: { name: 'fetch', model: 'Payment', params: { id } },
      force: true,
    })

    const processValueSaleOrder = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'sale_order' } },
    })

    if (processValueSaleOrder[0].id === this.payment.idProcess) {
      const evaluation = await this.fetchData({
        query: { name: 'find', model: 'Evaluation' },
        filter: {
          _and: [
            { status: { status: { name: { _eq: 'closed' } } } },
            { closing_reason: { type: { name: { _eq: 'signed' } } } },
            { financing: { id_sale_order: { _eq: this.payment.idProcessRecord } } },
          ],
        },
        force: true,
      })

      this.evaluation = evaluation[0]
    }
  }

  closePayment () {
    this.close()
  }

  async send () {
    const {
      formData: { exist, comment, paymentProof, confirm, letterApproval },
      payment,
      displayFile,
      filesProcess,
      filesPropertiesConfirm,
      paymentTypeFinancing,
      isSale,
    } = this

    if (exist === null) {
      this.radioButtonMessage = 'Este campo es requerido'
      return
    }

    if (!exist && !comment?.length) {
      this.$refs.payment.validate()
      return
    }

    if (exist && (!confirm.length && displayFile) && isSale) {
      this.$refs.payment.validate()
      return
    }

    if (isSale && !paymentTypeFinancing) {
      const status = exist ? this.status.successfull[0].id : this.status.toUpdate[0].id

      await this.updatePaymentStatus(payment, exist, status, comment)
    } else if (isSale && paymentTypeFinancing) {
      await this.pushData({
        model: 'Payment',
        fields: {
          id: payment.id,
          id_process_status: this.closed[0].id,
          id_closing_reason: this.status.successfull[0].id,
          id_responsible: this.idEmployee,
        },
      })
    }

    if (isSale && !exist) {
      await this.updateReserveOrSaleOrder(payment, exist)
    }

    if (exist && isSale) {
      await this.handleFileType(confirm, { properties: filesPropertiesConfirm }, filesProcess.payment, payment.id)
    }

    if (paymentProof?.length) {
      await this.insertFileProcessInfo({
        id: paymentProof[0].id,
        comment: !exist ? comment : '',
        valid: exist,
        validation_type: 'manual',
      })
    }

    if (letterApproval?.length) {
      await this.insertFileProcessInfo({
        id: letterApproval[0].id,
        comment: !exist ? comment : '',
        valid: exist,
        validation_type: 'manual',
      })
    }

    this.closePayment()
  }

  async updateReserveOrSaleOrder (payment, exist) {
    if (exist) return
    const processValueSaleOrder = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'sale_order' } },
    })

    const processValueReserve = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'reserve' } },
    })

    if (payment.idProcess === processValueSaleOrder[0].id) {
      const saleOrder = await this.fetchData({
        query: { name: 'fetchLite', model: 'SaleOrder', params: { id: payment.idProcessRecord } },
        force: true,
      })

      if (!saleOrder?.closingReason) {
        const status = await this.fetchData({
          query: { name: 'find', model: 'ProcessStatus' },
          filter: { _and: [{ process: { table_name: { _eq: 'sale_order' } } }, { status: { name: { _eq: 'to_update' } } }] },
        })
        await this.pushData({
          model: 'SaleOrder',
          fields: {
            id: payment.idProcessRecord,
            id_process_status: status[0].id,
          },
        })
      }
    }

    if (payment.idProcess === processValueReserve[0].id) {
      const reserve = await this.fetchData({
        query: { name: 'fetchLite', model: 'Reserve', params: { id: payment.idProcessRecord } },
        force: true,
      })

      if (!reserve?.closingReason) {
        const status = await this.fetchData({
          query: { name: 'find', model: 'ProcessStatus' },
          filter: { _and: [{ process: { table_name: { _eq: 'reserve' } } }, { status: { name: { _eq: 'to_update' } } }] },
        })
        await this.pushData({
          model: 'Reserve',
          fields: {
            id: payment.idProcessRecord,
            id_process_status: status[0].id,
          },
        })
      }
    }
  }

  confirmAction () {
    this.alert.open = false
    this.alert.title = ''
  }

  async updatePaymentStatus (payment, exist, status, comment) {
    let fields
    if (!exist) {
      fields = {
        id: payment.id,
        id_process_status: status,
        id_responsible: this.idEmployee,
        comment,
      }
    } else {
      fields = {
        id: payment.id,
        id_process_status: this.closed[0].id,
        id_closing_reason: status,
        id_responsible: this.idEmployee,
        comment,
      }
    }

    await this.pushData({
      model: 'Payment',
      fields,
    })
  }

  @Watch('formData.exist', { immediate: true })
  onExistChange (newValue: boolean | null) {
    if (newValue === null) return
    this.radioButtonMessage = ''
  }

  get paymentAndProcess () {
    const { payment, filesProcess, evaluation } = this
    return { payment, filesProcess, evaluation }
  }

  @Watch('paymentAndProcess', { immediate: true, deep: true })
  @Debounce()
  async onProcessTrafficTicketChange ({ payment, filesProcess }) {
    if (!payment?.id || !filesProcess?.payment) return

    if (payment.id && this.isSale && !this.paymentTypeFinancing) {
      this.formData.date = payment.dateFormatted

      const files = await this.fetchData({
        query: { name: 'find', model: 'FileProcess' },
        filter: {
          _and: [
            { id_process_record: { _eq: payment.id } },
            { parameter: { process: { id: { _eq: filesProcess.payment } } } },
          ],
        },
        force: true,
      })

      this.formData.paymentProof = files.filter(file => file.parameter.name === 'payment_receipt')
      this.formData.confirm = files.filter(file => file.parameter.name === 'payment_confirmation')
      this.formData.exist = this.formData.paymentProof[0].valid
    } else if (payment.id && this.isSale && this.paymentTypeFinancing) {
      if (!this.evaluation?.id) return
      const files = await this.fetchData({
        query: { name: 'find', model: 'FileProcess' },
        filter: {
          _and: [
            { id_process_record: { _eq: this.evaluation?.id } },
            { parameter: { process: { id: { _eq: filesProcess.evaluation } } } },
          ],
        },
        force: true,
      })
      const filesPayment = await this.fetchData({
        query: { name: 'find', model: 'FileProcess' },
        filter: {
          _and: [
            { id_process_record: { _eq: payment.id } },
            { parameter: { process: { id: { _eq: filesProcess.payment } } } },
          ],
        },
        force: true,
      })

      this.formData.letterApproval = files.filter(file => file.parameter.name === 'approval_letter')
      this.formData.exist = this.formData.letterApproval[0].valid
      this.formData.confirm = filesPayment.filter(file => file.parameter.name === 'payment_confirmation')
    } else {
      const files = await this.fetchData({
        query: { name: 'find', model: 'FileProcess' },
        filter: {
          _and: [
            { id_process_record: { _eq: payment.id } },
            { parameter: { process: { id: { _eq: filesProcess.payment } } } },
          ],
        },
        force: true,
      })

      this.formData.purchaseBackup = files?.filter(file => file.parameter.name === 'spending_support') || []
    }
  }

  get displayFile () {
    const { payment: { type }, payment } = this

    if (!type) return

    let otherTypes = []
    if (!payment?.paymentRecipient) {
      otherTypes = ['debit_card', 'credit_card', 'bank_deposit', 'electronic_transfer', 'financing']
    }

    const types = [...otherTypes]

    return types.includes(type.name)
  }

  get disabledFields () {
    return this.paymentIsClosed
  }

  get paymentName () {
    const { isSale } = this

    return isSale ? 'Pagador' : 'Recibe'
  }

  get labelPay () {
    const { isSale } = this

    return isSale ? '¿Válida pago?' : '¿Autoriza gasto?'
  }

  get personName () {
    const { isSale, payment } = this

    return isSale ? payment.payer.fullName : payment?.paymentRecipient?.person.fullName
  }

  get personUid () {
    const { isSale, payment } = this

    return isSale ? payment.payer?.uid : payment?.paymentRecipient?.person?.uid
  }

  get paymentTypeFinancing () {
    const { payment: { type } } = this

    return type?.name === 'financing'
  }

  get paymentTypeVehiclePartially () {
    const { payment: { type } } = this

    return type.name === 'vehicle_partially_paid'
  }

  get paymentIsClosed () {
    return this.payment?.status?.isClosed
  }

  get change () {
    const { payment, formData } = this

    return stringifySafe([formData, payment])
  }
  }
